body {
  background-color: #fdfdfd;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  margin: 0;
  font-family: Gill Sans, sans-serif;
  display: flex;
}

.wrapper {
  height: 100%;
}

.header-w {
  color: #023047;
  margin-block: 0;
  margin-inline: 0;
  font-size: 1em;
  font-weight: bold;
}

.sub-header {
  margin-block: 0;
  margin-inline: 0;
  font-size: .9em;
  font-style: italic;
  font-weight: normal;
}

@media only screen and (width <= 1024px) {
  .img-1 {
    width: 12%;
    height: auto;
  }

  .img-2 {
    width: 17%;
    height: auto;
  }

  .img-3 {
    width: 32%;
    height: auto;
    margin-top: 24px;
  }

  .img-4 {
    width: 17%;
    height: auto;
  }

  .img-5 {
    width: 12%;
    height: auto;
  }
}

.content-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 32px;
  display: flex;
  overflow: scroll;
}

@media only screen and (width <= 480px) {
  .header-w {
    margin-block: 0;
    margin-inline: 0;
    font-size: 1em;
    font-weight: bold;
  }

  .sub-header {
    margin-block: 0;
    margin-inline: 0;
    font-size: .9em;
    font-style: italic;
    font-weight: normal;
  }

  .content-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 48px;
    display: flex;
    overflow: scroll;
  }
}

.header-w {
  background-color: #fb8500;
  flex-direction: row;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
}

.header-left {
  border-right: 8px solid #219ebc;
  width: 36px;
}

.header-left-inner {
  border-right: 16px solid #ffb703;
  height: 100%;
}

.header-right {
  padding: 8px 16px 8px 8px;
}

.content {
  max-width: 1024px;
  padding: 16px;
  font-size: 1.2em;
  line-height: 1.6em;
}

.footer {
  color: #023047;
  background-color: #fb8500;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px;
  font-size: .9em;
  display: flex;
  position: sticky;
  bottom: 0;
}

.img-1 {
  margin-right: -5px;
}

.img-2, .img-3, .img-4, .img-5 {
  margin-right: -4px;
}

a {
  color: #219ebc;
}

.mail {
  color: #023047;
  font-size: 32px;
  position: absolute;
  top: 4px;
  left: 4px;
}

/*# sourceMappingURL=index.404a9ea6.css.map */
