body {
  font-family: "Gill Sans", sans-serif;
  margin: 0px;
  background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}
.wrapper {
  height: 100%;
}
.header-w {
  font-size: 1em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: #023047;
}
.sub-header {
  font-size: 0.9em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: normal;
  font-style: italic;
}
@media only screen and (max-width: 1024px) {
  .img-1 {
    width: calc(12%);
    height: auto;
  }
  .img-2 {
    width: calc(17%);
    height: auto;
  }
  .img-3 {
    margin-top: 24px;
    width: calc(32%);
    height: auto;
  }
  .img-4 {
    width: calc(17%);
    height: auto;
  }
  .img-5 {
    width: calc(12%);
    height: auto;
  }
}

.content-container {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: scroll;
}

@media only screen and (max-width: 480px) {
  .header-w {
    font-size: 1em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  .sub-header {
    font-size: 0.9em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: normal;
    font-style: italic;
  }
  .content-container {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: scroll;
  }
}
.header-w {
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: #fb8500;
  display: flex;
  flex-direction: row;
}
.header-left {
  width: 36px;
  border-right: 8px solid #219ebc;
}
.header-left-inner {
  height: 100%;
  border-right: 16px solid #ffb703;
}
.header-right {
  padding: 8px;
  padding-right: 16px;
}

.content {
  font-size: 1.2em;
  padding: 16px;
  line-height: 1.6em;
  max-width: 1024px;
}
.footer {
  font-size: 0.9em;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 8px 8px 8px 8px;
  background-color: #fb8500;
  color: #fff;
  position: sticky;
  bottom: 0px;
  color: #023047;
}
.img-1 {
  margin-right: -5px;
}
.img-2 {
  margin-right: -4px;
}
.img-3 {
  margin-right: -4px;
}
.img-4 {
  margin-right: -4px;
}
.img-5 {
  margin-right: -4px;
}
a {
  color: #219ebc;
}
.mail {
  font-size: 32px;
  position: absolute;
  top: 4px;
  left: 4px;
  color: #023047;
}
